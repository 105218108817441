import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Table, Tabs, ProviderCodesSelect, SearchInput } from 'components';
import { logger, withTracker, permissions } from 'helpers';
import * as actions from 'store/actions';
import tabsConfig from './Configs/tabs';
import tableConfig from './Configs/table';
import {
	getProvidersOptions,
	getTabParams,
	getFiltersParam,
	errorsParams,
	getSearchInputParams,
	getSuggestionsParams,
	modelsParams,
} from './Configs/paramsCreator';
import { Delete } from 'components/Icons';
import { makeGetAllReportsState } from 'store/selectors';

import s from './style.module.scss';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

class Reports extends Component {
	constructor(props) {
		super(props);

		withTracker({
			page: props.location.pathname,
			category: 'Reports',
			action: 'Page view',
		});

		props.getModels({ params: modelsParams });
		props.getFirmwares();
		props.getErrors({ params: errorsParams });
		props.getStates();

		if (permissions.check('providers.main.filter')) {
			props.getProviders(getProvidersOptions());
		} else {
			props.getPinCodes();
		}

		this.state = {
			searchValue: '',
			activeTab: {
				index: 0,
				name: 'column_life',
			},
			tableFilters: {},
			filters: {},
			reportLoading: false,
		};
		this.searchRef = React.createRef();
		this.inogenProvidersFlag = true;
		this.values = [];
		this.InogenFlag = false;
		this.NonInogenflag = false;
	}

	componentWillUnmount() {
		logger('[Reports] componentWillUnmount');

		this.props.clearReports();
	}

	componentDidMount() {
		if (this.props.location.state && this.props.location.state.error) {
			this.paramsFromRedirect = {
				concentratorErrorRecallLastCode: [this.props.location.state.error.code],
				concentrator_model_id: this.props.location.state.models[0]
					? this.props.location.state.models[0]
					: '',
				error_id: [this.props.location.state.error.error_id],
			};

			this.onTabClick('list_of_errors', 2);
		} else {
			this.props.getProfilesReport(this.props.requestParams);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		logger('[Reports] componentDidUpdate');

		const { requestParams, getProfilesReport, setRequestParam } = this.props;
		const {
			filters,
			activeTab,
			activeTab: { name },
		} = this.state;
		const { providers = {}, pinCodes = {} } = filters;
		let providerIds = Object.keys(providers).length
			? Object.keys(providers)
			: null;
		const pinCodeIds = Object.keys(pinCodes).length
			? Object.keys(pinCodes)
			: null;
		const pinCodeData = Object.values(pinCodes).length
			? Object.values(pinCodes)
			: null;
		const isParamsChange = requestParams !== prevProps.requestParams;
		const isFiltersChange = filters !== prevState.filters;
		const isTabChange = activeTab !== prevState.activeTab;

		if (isParamsChange) {
			getProfilesReport(requestParams);
			this.tableOffset = requestParams.limit;
		}

		if (isTabChange) {
			if (this.paramsFromRedirect) {
				setRequestParam(getTabParams(name, this.paramsFromRedirect));
				this.updateStateAfterRedirect();
				this.paramsFromRedirect = null;
			} else {
				setRequestParam(getTabParams(name));
			}
		}

		if (isFiltersChange) {
			const parentId = {},
				parentProvidersId = [];
			pinCodeData &&
				pinCodeData.length > 0 &&
				pinCodeData.map((data) => {
					if (data.id && data.provider_id) {
						parentId['pincode:' + data.id] = data.provider_id;
					}
					return '';
				});
			if (pinCodeIds !== null) {
				pinCodeIds.length > 0 &&
					pinCodeIds.map((pinCode) => {
						parentProvidersId.push(parentId['pincode:' + pinCode]);
						return '';
					});
			}
			if (providerIds !== null) {
				parentProvidersId &&
					parentProvidersId.map((data) => {
						providerIds.push(data);
						return '';
					});
			} else {
				providerIds = parentProvidersId;
			}
			if (!providerIds.length > 0) {
				providerIds = null;
			} else {
				/* to remove the duplcates*/
				providerIds = [...new Set(providerIds)];
			}
			setRequestParam(getFiltersParam(providerIds, pinCodeIds));
		}

		if (
			this.searchRef.current &&
			this.props.location.search.indexOf('search') > 0
		) {
			this.searchRef.current.focus();
		}
	}
	onSearchTyping = (event, { newValue }) => {
		this.setState({ searchValue: newValue });

		if (!newValue) {
			this.onSearchSubmit(null);
		}
	};
	onSuggestions = () => {
		const { viewMode, searchValue } = this.state;
		const options = {
			prevParams: this.props.requestParams,
			value: searchValue,
			key: 'serial_number',
		};
		const args = {
			viewMode: viewMode,
			params: getSuggestionsParams(options),
		};

		this.props.getSearchData(args);
	};
	onSearchSubmit = (value) => {
		const params = getSearchInputParams(value);

		ReactGA.event({
			category: 'Concentrators',
			action: 'Submit',
			label: 'Search field',
		});

		this.props.setRequestParam(params);
	};

	onSearchClear = () => {
		this.setState({ searchValue: '' });
		this.onSearchSubmit(null);
	};

	onTabClick = (name, index) => {
		ReactGA.event({
			category: 'Reports',
			action: 'Click tab',
			label: `View ${name}`,
		});

		this.setState({
			activeTab: { index, name },
			tableFilters: {
				errors: {},
				firmwares: {},
				states: {},
				firstDate: {},
				lastDate: {},
				lastUpdate: {},
			},
			reportLoading: false,
		});
	};

	updateStateAfterRedirect = () => {
		const errorData = this.props.location.state.error;
		const errorIndex = this.getErrorIndex(errorData.description);
		const models = this.props.location.state.models;
		const providers = this.props.location.state.providers || {};

		const params = {
			errors: {
				[errorIndex]: {
					code: [errorData.code],
					description: errorData.description,
					error_id: errorData.error_id,
				},
			},
			models: {
				[models[0]]: this.getModelData(models[0]),
			},
		};

		this.setState({
			tableFilters: {
				...this.state.tableFilters,
				errors: params.errors,
				models: params.models,
			},
			filters: {
				...this.state.filters,
				providers: providers,
			},
		});

		this.props.history.replace({
			state: {},
		});
	};

	loadMoreHandler = () => {
		const { requestParams, getProfilesReport } = this.props;
		const params = {
			...requestParams,
			offset: this.tableOffset,
		};

		this.tableOffset += requestParams.limit;
		getProfilesReport(params, true);
	};

	onFilterAdd = (item, key, inogenProviders, parent) => {
		this.inogenProvidersFlag = inogenProviders;
		ReactGA.event({
			category: 'Reports',
			action: 'Click',
			label: `Select ${key} filter`,
		});

		this.setState((ps) => {
			const filters = { ...ps.filters };

			if (item) {
				filters[key]
					? filters[key][item.id]
						? delete filters[key][item.id]
						: (filters[key][item.id] = item)
					: (filters[key] = { [item.id]: item });
			} else {
				filters['pinCodes'] = {};
				filters['providers'] = {};
			}

			if (key === 'pinCodes') {
				const isAllChecked =
					parent && parent.provider_codes.every((el) => filters[key][el.id]);

				if (isAllChecked) {
					filters.providers
						? (filters.providers[parent.id] = parent)
						: (filters.providers = { [parent.id]: parent });

					parent.provider_codes.forEach((el) => {
						filters[key][el.id] && delete filters[key][el.id];
					});
				} else if (filters.providers) {
					filters.providers[item.provider_id] &&
						delete filters.providers[item.provider_id];
				}
			}

			if (key === 'providers' && filters.pinCodes) {
				filters[key][item.id] &&
					filters[key][item.id].provider_codes.forEach((el) => {
						filters.pinCodes[el.id] && delete filters.pinCodes[el.id];
					});
			}
			const filtererdArray =
				filters.providers && Object.values(filters.providers);
			this.values =
				filtererdArray &&
				filtererdArray.length > 0 &&
				filtererdArray.map((obj) => obj['name']);

			return { filters };
		});
	};

	getErrorIndex = (codeString) => {
		const error_obj =
			this.props.errorsData &&
			this.props.errorsData.length > 0 &&
			this.props.errorsData.find((error) => error.code_string === codeString);
		return error_obj.id;
	};

	getModelData = (id) => {
		const currentModel = {};

		this.props.modelsData.find((model) => {
			if (model.id === id) {
				currentModel.name = model.name;
				currentModel.id = model.id;

				return true;
			}
			return false;
		});

		return currentModel;
	};

	tableOffset = this.props.requestParams.limit;

	render() {
		logger('[Reports] Render');

		const {
			providersData,
			profilesData,
			pinCodesData,
			downloadReport,
			requestParams,
			tableLoading,
			setRequestParam,
			hasMoreProfilesData,
			currentUserData,
			t,
		} = this.props;

		const {
			activeTab,
			reportLoading,
			filters,
			tableFilters,
			tableFilters: { firstDate, lastDate, lastUpdate },
		} = this.state;
		const tableClasses = [s.table];

		const downloadStartHandler = () => this.setState({ reportLoading: true });
		const downloadEndHandler = () => this.setState({ reportLoading: false });

		if (!activeTab.index) {
			tableClasses.push(s.stateLeft);
		}

		if (activeTab.name === 'software') {
			tableClasses.push(s[activeTab.name]);
		}
		this.InogenFlag = false;
		this.NonInogenflag = false;
		this.InogenProviders = true;
		currentUserData &&
			currentUserData.roles &&
			currentUserData.roles.length > 0 &&
			currentUserData.roles.map((userData) => {
				return userData;
			});
		this.values &&
			this.values.length > 0 &&
			this.values.map((data) => {
				if (
					data &&
					(data === t('inogen') || data === t('inogen_engineering'))
				) {
					this.InogenFlag = true;
				} else {
					this.NonInogenflag = true;
				}
				return data;
			});
		this.InogenFlag && this.NonInogenflag
			? (this.InogenProviders = false)
			: this.InogenFlag
			  ? (this.InogenProviders = true)
			  : this.NonInogenflag
			    ? (this.InogenProviders = false)
			    : (this.InogenProviders = true);

		const filterProps = (filterName) => ({
			checked: tableFilters[filterName] || {},
			data:
				filterName === t('models') && this.props['modelsData'].length > 0
					? this.props['modelsData']
					: this.props[`${filterName}Data`],
			onItemClick: (data, config) => {
				this.setState(
					(prevState) => {
						const prevFilters = { ...prevState.tableFilters };
						const filter = prevFilters[filterName] || {};

						if (filter[data.id]) {
							delete filter[data.id];
						} else {
							if (filterName === 'errors') {
								filter[data.id] = {
									[config.displayField]: data[config.displayField],
									[config.valuesField]: data[config.valuesField],
									[config.codeField]: data[config.codeField],
								};
							} else {
								filter[data.id] = {
									[config.displayField]: data[config.displayField],
									[config.valuesField]: data[config.valuesField],
								};
							}
						}

						prevFilters[filterName] = filter;

						return { tableFilters: prevFilters };
					},
					() => {
						const { valuesField, param, codeField } = config;
						let values = [],
							error_id = [];
						const filter = this.state.tableFilters[filterName] || {};

						if (filterName === 'errors') {
							values = Object.values(filter).map((item) => item[valuesField]);
							error_id = Object.values(filter).map((item) => item[codeField]);
							setRequestParam({
								search: {
									[param]: values.length ? values : null,
								},
								searchFields: {
									[param]: values.length ? 'in' : null,
								},

								codeFilter: {
									[codeField]: error_id.length ? error_id : null,
								},
							});
						} else {
							values = Object.values(filter).map((item) => item[valuesField]);
							setRequestParam({
								search: {
									[param]: values.length ? values : null,
								},
								searchFields: {
									[param]: values.length ? 'in' : null,
								},
							});
						}
					},
				);
			},
			defaultChanged: (config) => {
				let codeFilterFlag = false;
				if (
					this.state.filters &&
					this.state.filters.errors &&
					Object.values(this.state.filters.errors).length > 0
				) {
					codeFilterFlag = true;
				}
				if (config.defaultValue === 'all_errors') {
					codeFilterFlag = false;
				}
				codeFilterFlag
					? setRequestParam({
							search: {
								[config.param]: null,
							},
							searchFields: {
								[config.param]: null,
							},
					  })
					: setRequestParam({
							search: {
								[config.param]: null,
							},
							searchFields: {
								[config.param]: null,
							},
							codeFilter: {
								[config.codeField]: null,
							},
					  });
			},
			clear: () =>
				this.setState((prevState) => ({
					tableFilters: {
						...prevState.tableFilters,
						[filterName]: {},
					},
				})),
		});

		const dateFilterProps = (dateState = {}, filterName) => ({
			checked: dateState.checked,
			singleDate: dateState.singleDate,
			toDate: dateState.toDate,
			fromDate: dateState.fromDate,
			singleChange: (date, config) => {
				this.setState(
					(prevState) => {
						const filtersState = { ...prevState.tableFilters };
						const filter = filtersState[filterName] || {};

						filter.singleDate = date;
						filter.fromDate = null;
						filter.toDate = null;

						filtersState[filterName] = filter;

						return { tableFilters: filtersState };
					},
					() => {
						this.props.setRequestParam({
							search: {
								[config.param]: [
									dateState.singleDate
										.startOf('day')
										.format('YYYY-MM-DDTHHmmss'),
									dateState.singleDate.endOf('day').format('YYYY-MM-DDTHHmmss'),
								],
							},
							searchFields: { [config.param]: 'between' },
						});
					},
				);
			},
			fromChange: (date, config) => {
				this.setState(
					(prevState) => {
						const filtersState = { ...prevState.tableFilters };
						const filter = filtersState[filterName] || {};

						if (moment(date).isAfter(filter.toDate)) {
							filter.fromDate = date;
							filter.toDate = null;
							filter.singleDate = null;
						} else {
							filter.fromDate = date;
							filter.singleDate = null;
						}

						filtersState[filterName] = filter;

						return { tableFilters: filtersState };
					},
					() => {
						if (dateState.toDate) {
							this.props.setRequestParam({
								search: {
									[config.param]: [
										date.startOf('day').format('YYYY-MM-DDTHHmmss'),
										dateState.toDate.endOf('day').format('YYYY-MM-DDTHHmmss'),
									],
								},
								searchFields: { [config.param]: 'between' },
							});
						}
					},
				);
			},
			toChange: (date, config) => {
				this.setState(
					(prevState) => {
						const filtersState = { ...prevState.tableFilters };
						const filter = filtersState[filterName] || {};

						if (moment(date).isBefore(filter.fromDate)) {
							filter.fromDate = null;
							filter.toDate = date;
							filter.singleDate = null;
						} else {
							filter.toDate = date;
							filter.singleDate = null;
						}

						filtersState[filterName] = filter;

						return { tableFilters: filtersState };
					},
					() => {
						if (dateState.fromDate) {
							this.props.setRequestParam({
								search: {
									[config.param]: [
										dateState.fromDate
											.startOf('day')
											.format('YYYY-MM-DDTHHmmss'),
										date.endOf('day').format('YYYY-MM-DDTHHmmss'),
									],
								},
								searchFields: { [config.param]: 'between' },
							});
						}
					},
				);
			},
			clearSelect: (config) =>
				this.props.setRequestParam({
					search: { [config.param]: null },
					searchFields: { [config.param]: null },
				}),
			itemClicked: (el) => {
				this.setState((prevState) => {
					const filtersState = { ...prevState.tableFilters };
					const filter = filtersState[filterName] || {};

					filter.checked = el.id;
					filter.singleDate = null;
					filter.fromDate = null;
					filter.toDate = null;

					filtersState[filterName] = filter;

					return { tableFilters: filtersState };
				});
			},
			clear: () =>
				this.setState((prevState) => ({
					tableFilters: {
						...prevState.tableFilters,
						date: {
							checked: 1,
							singleDate: null,
							toDate: null,
							fromDate: null,
						},
					},
				})),
		});

		const filterArray = Object.values(filters);
		const filtersGroup = Object.keys(filters);
		const filterLabels = [];

		if (filterArray.length) {
			filterArray.map((el, i) => {
				filterLabels.push(
					Object.values(el).map((el, index) => {
						return (
							<span className={s.filterItem} key={index}>
								{el.name || el.label || el.description || el.code}
								<Delete
									className={s.icon}
									onClick={() => this.onFilterAdd(el, filtersGroup[i])}
								/>
							</span>
						);
					}),
				);

				return el;
			});
		}

		return (
			<div className={s.page}>
				<div className={s.content}>
					<h1 className={s.pageTitle}>
						{i18next.t('reports')}
						<ProviderCodesSelect
							data={
								permissions.check('providers.main.filter')
									? providersData
									: pinCodesData
							}
							mainChecked={
								permissions.check('providers.main.filter')
									? filters.providers
									: filters.pinCodes
							}
							subChecked={filters.pinCodes}
							onItemClick={this.onFilterAdd}
						/>
					</h1>
					<div className={s.searchPanel}>
						<SearchInput
							inputRef={this.searchRef}
							value={this.state.searchValue}
							placeholder={t('search_by_serial_number')}
							disabled={this.props.tableLoading}
							onChange={this.onSearchTyping}
							suggestions={this.props.searchData ? this.props.searchData : ''}
							onSuggestions={this.onSuggestions}
							onSuggestionsClear={this.props.clearSearchData}
							onSearchSubmit={() => this.onSearchSubmit(this.state.searchValue)}
							onClear={this.onSearchClear}
						/>
					</div>

					<div>{filterLabels}</div>

					<Tabs
						className={s.tableTabs}
						activeClassName={s.activeTab}
						data={tabsConfig}
						activeItem={activeTab.index}
						onItemClick={this.onTabClick}
					/>

					<Table
						className={tableClasses.join(' ')}
						config={tableConfig[activeTab.index]}
						data={profilesData}
						downloadReport={downloadReport}
						requestParams={requestParams}
						loading={tableLoading}
						onSetParams={setRequestParam}
						loadMoreHandler={this.loadMoreHandler}
						hasMoreData={hasMoreProfilesData}
						onDownloadStart={downloadStartHandler}
						onDownloadEnd={downloadEndHandler}
						reportLoading={reportLoading}
						statesFilterProps={filterProps('states')}
						firmwaresFilterProps={filterProps('firmwares')}
						errorsFilterProps={filterProps('errors')}
						modelsFilterProps={filterProps('models')}
						firstConnectFilterProps={dateFilterProps(firstDate, 'firstDate')}
						lastConnectFilterProps={dateFilterProps(lastDate, 'lastDate')}
						lastUpdateFilterProps={dateFilterProps(lastUpdate, 'lastUpdate')}
						infinite
					/>
				</div>
			</div>
		);
	}
}

const mapStateToProps = () => {
	const getAllReportsState = makeGetAllReportsState();

	return (props, state) => getAllReportsState(props, state);
};

const mapDispatchToProps = (dispatch) => {
	return {
		getProfilesReport: (options, isMerge) =>
			dispatch(actions.getProfilesReport(options, isMerge)),
		getFirmwares: (options) => dispatch(actions.getFirmwares(options)),
		getCurrentUser: () => dispatch(actions.getCurrentUser()),
		getErrors: (args) => dispatch(actions.getErrors(args)),
		getModels: (args) => dispatch(actions.getModels(args)),
		downloadReport: (params, type) => actions.downloadReport(params, type),
		clearReports: () => dispatch(actions.clearReports()),
		clearSearchData: () => dispatch(actions.clearSearchData()),
		getSearchData: (options) => dispatch(actions.getSearchData(options)),
		setRequestParam: (params) => dispatch(actions.setRequestParam(params)),
		getStates: (args) => dispatch(actions.getStates(args)),
		setFilter: (options) => dispatch(actions.setReportFilter(options)),
		removeFilter: (key, item) =>
			dispatch(actions.removeReportFilter(key, item)),
		getProviders: (options) => dispatch(actions.getProviders(options)),
		getPinCodes: (args) => dispatch(actions.getPinCodes(args)),
	};
};
Reports.propTypes = {
	modelsData: PropTypes.array.isRequired,
};
export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withTranslation()(Reports));
